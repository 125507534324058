import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {lastValueFrom} from 'rxjs';
import {AbstraktniService, TypPozadavku} from './abstraktni.service';
import {prostredi} from '../../prostredi/prostredi';
import {UpozorneniService} from './upozorneni.service';
import {AutentizaceService} from './bezpecnost/autentizace.service';
import {DetailSubjektu} from '../data/muj-subjekt/detail-subjektu';
import {NastrojeObecne} from '../shared/nastroje/nastroje-obecne';

@Injectable()
export class MujSubjektService extends AbstraktniService {

	private readonly vzorCestyDetailuSubjektu = '/subjekt/detail/{0}';

	constructor(
		protected http: HttpClient,
		protected autentizaceService: AutentizaceService,
		protected upozorneniService: UpozorneniService
	) {
		super(http, prostredi.urlSpravaSubjektuAUzivatelu, autentizaceService, upozorneniService);
	}

	public pripravitDetailSubjektu(idSubjektu: string): Promise<DetailSubjektu> {
		return lastValueFrom(this.pozadavekSTokenem(TypPozadavku.GET, NastrojeObecne.formatovatText(this.vzorCestyDetailuSubjektu, idSubjektu)));
	}
}
