<header class="gov-container gov-container--wide gov-container--no-y-offset gov-header" [class.color-ref]="zobrazitHlavickuVReferecnimModu">
	<div class="gov-container__content">
		<div class="gov-header__holder">
			<div class="gov-header__left" style="display: flex;">
				<a href="https://mmr.cz/cs/uvod" target="_blank" rel="noreferrer noopener" class="gov-footer__image-link">
					<img alt="MMR logo" src="/assets/obrazky/loga/mmr_cr_negativni.a57d54b14d68578b055170de4eb73483.svg" width="130">
				</a>
				<a class="gov-logo gov-logo--inversed" [class.gov-logo-ref]="zobrazitHlavickuVReferecnimModu" href="{{ urlVerejneAplikace }}">
					VĚSTNÍK VEŘEJNÝCH ZAKÁZEK
					<small *ngIf="zobrazitHlavickuVReferecnimModu">TESTOVACÍ PROSTŘEDÍ</small>
				</a>
			</div>

			<div class="gov-header__right">
				<div *ngIf="!jeUzivatelPrihlasen"
					 class="gov-header__item gov-header__item--login">
					<button class="gov-button gov-button--primary-outlined gov-button--inversed"
							(click)="prihlasit()">{{ 'hlavicka.prihlasitSe' | lokalizace }}</button>
					<button class="gov-button gov-button--primary-outlined gov-button--inversed u-ms--10"
							routerLink="/registrace">{{ 'hlavicka.registrovat' | lokalizace }}</button>
				</div>

				<div *ngIf="jeUzivatelPrihlasen" class="gov-header__item gov-header__item--login">
					<a class="gov-button gov-button--inversed" routerLink="/muj-ucet" title="{{ nazevSubjektu }}">
						<div class="login-info">
							<span>{{ jmenoAPrijmeni }}</span>
							<span class="login-info__role">({{ rolePrihlasenehoUzivateleJeAdministratorSubjektu ? ('role.administratorSubjektu' | lokalizace) : ('role.uzivatelSubjektu' | lokalizace) }})</span>
						</div>
					</a>

					<button class="odhlasit gov-button gov-button--small gov-button--inversed"
							title="{{ 'hlavicka.odhlasitSe' | lokalizace }}"
							(click)="odhlasit()"><span class="gov-icon gov-icon--exit"></span></button>
				</div>

				<div class="gov-header__fixed-items">
					<div class="gov-header__item gov-header__item--mobile">
						<button class="gov-header__hamburger"
								[class.active]="jeMobilniMenuAktivni"
								(click)="aktivovatMobilniMenu()"><span></span><span></span>
							<div>{{ 'hlavicka.menu' | lokalizace }}</div>
						</button>
					</div>
					<div class="gov-header__item gov-header__lang">
						<div class="gov-lang-switch">
							<select [(ngModel)]='nastavenaLokalizace'
									id="select-lang"
									class="gov-lang-switch__select"
									title="{{ 'hlavicka.zmenaJazyka' | lokalizace }}"
									(change)="prepnoutLokalizaci($event)">
								<option
									*ngFor="let lokalizace of seznamLokalizaci; index as i"
									value="{{ lokalizace }}">{{ seznamNazvuLokalizaci[i] }}</option>
							</select>
							<label class="u-sr-only"
								   for="select-lang">{{ 'hlavicka.zmenaJazyka' | lokalizace }}</label>
						</div>
					</div>
				</div>
			</div>
		</div>

		<hr class="gov-header__separator" [class.gov-header__separator-white]="zobrazitHlavickuVReferecnimModu">
		<div class="gov-header__overlay"
			 [class.active]="jeMobilniMenuAktivni"
			 (click)="aktivovatMobilniMenu()"></div>

		<nav class="gov-header__nav"
			 [class.active]="jeMobilniMenuAktivni">
			<div class="gov-header__nav-logo"
				 [class.active]="jeMobilniMenuAktivni"></div>
			<div class="gov-header__nav-holder" [class.gov-header__nav-holder-ref]="zobrazitHlavickuVReferecnimModu">
				<div class="gov-header__link">
					<a class="gov-link gov-link--standalone gov-link--large gov-link--inversed"
					   href="{{ urlVerejneAplikace + '/' }}">{{ 'hlavicka.uvod' | lokalizace }}</a>
				</div>
				<div *ngIf="jeUzivatelPrihlasen" class="gov-header__link">
					<a class="gov-link gov-link--standalone gov-link--large gov-link--inversed"
					   href="{{ urlVerejneAplikace + '/moje-vyhlaseni' }}">{{ 'hlavicka.mojeVyhlaseni' | lokalizace }}</a>
				</div>
				<div *ngIf="jeUzivatelPrihlasen" class="gov-header__link">
					<a class="gov-link gov-link--standalone gov-link--large gov-link--inversed"
					   href="{{ urlVerejneAplikace + '/podat-formular' }}">{{ 'hlavicka.podatFormular' | lokalizace }}</a>
				</div>
				<div class="gov-header__link">
					<a class="gov-link gov-link--standalone gov-link--large gov-link--inversed"
					   href="{{ urlVerejneAplikace + '/vyhledat-formular' }}">{{ 'hlavicka.vyhledatFormular' | lokalizace }}</a>
				</div>
				<div class="gov-header__link">
					<a class="gov-link gov-link--standalone gov-link--large gov-link--inversed"
					   href="{{ urlVerejneAplikace + '/vyhledat-profil' }}">{{ 'hlavicka.vyhledatProfil' | lokalizace }}</a>
				</div>
				<div *ngIf="!jeUzivatelPrihlasen" class="gov-header__link" routerLinkActive="is-active">
					<a class="gov-link gov-link--standalone gov-link--large gov-link--inversed"
							(click)="otevritModalProOdberUpozorneni()">{{ 'hlavicka.odebiratUpozorneniNaNoveVZ' | lokalizace }}</a>
				</div>
				<div class="gov-header__link">
					<a class="gov-link gov-link--standalone gov-link--large gov-link--inversed"
					   href="{{ urlVerejneAplikace + '/napoveda-dokumentace' }}">{{ 'hlavicka.napovedaADokumentace' | lokalizace }}</a>
				</div>

				<div *ngIf="!jeUzivatelPrihlasen" class="mobilniMenuUzivatelOdhlasen">
					<button class="gov-link--standalone gov-button gov-button--primary-outlined gov-button--inversed"
							[class.active]="jeMobilniMenuAktivni"
							(click)="prihlasit()">{{ 'hlavicka.prihlasitSe' | lokalizace }}</button>

					<button class="gov-link--standalone gov-button gov-button--primary-outlined gov-button--inversed u-ms--10"
							[class.active]="jeMobilniMenuAktivni"
							routerLink="/registrace">{{ 'hlavicka.registrovat' | lokalizace }}</button>
				</div>
				<div *ngIf="jeUzivatelPrihlasen" class="mobilniMenuUzivatelPrihlasen">
					<a class="jmeno-prihlaseneho gov-link--standalone gov-link gov-button gov-button--inversed" routerLink="/muj-ucet" [class.active]="jeMobilniMenuAktivni">
						<div class="login-info">
							<span>{{ jmenoAPrijmeni }}</span>
							<span class="login-info__role">({{ rolePrihlasenehoUzivateleJeAdministratorSubjektu ? ('role.administratorSubjektu' | lokalizace) : ('role.uzivatelSubjektu' | lokalizace) }})</span>
						</div>
					</a>

					<button class="odhlasit gov-link--standalone gov-button gov-button--small gov-button--inversed"
							title="{{ 'hlavicka.odhlasitSe' | lokalizace }}"
							[class.active]="jeMobilniMenuAktivni"
							(click)="odhlasit()">{{ 'hlavicka.odhlasitSe' | lokalizace }}</button>
				</div>
			</div>
		</nav>
	</div>
</header>
